/*
 * @Author: your name
 * @Date: 2021-10-19 11:17:31
 * @LastEditTime: 2021-10-21 15:45:46
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \cloud_classroom_pc\src\router\index.js
 */
import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "layout",
    component: () => import("@/views/layout/index.vue"),
    redirect: "/home",
    children: [
      {
        path: "/liveBroadcast",
        name: "liveBroadcast",
        component: () => import("@/views/live-broadcast/index"),
      },
      {
        path: "/course",
        name: "course",
        component: () => import("@/views/course/course"),
      },
      {
        path: "/home",
        name: "home",
        component: () => import("@/views/home/home"),
      },
      {
        path: "/courseDetail",
        name: "courseDetail",
        component: () => import("@/views/course/courseDetail"),
      },
      {
        path: "/questionBank",
        name: "questionBank",
        component: () => import("@/views/question-bank/index"),
      },
      // 准考证信息页面
      {
        path: "/loginInterface",
        name: "loginInterface",
        component: () => import("@/views/practiceTest/loginInterface"),
      },
      // 答题信息页面
      {
        path: "/answerPage",
        name: "answerPage",
        component: () => import("@/views/practiceTest/answerPage"),
      },
      {
        path: "/selectClass",
        name: "selectClass",
        component: () => import("@/views/question-bank/selectClass"),
      },

      {
        path: "/classDetail",
        name: "classDeatail",
        component: () => import("@/views/course/classDetail"),
      },
      {
        path: "/login",
        name: "login",
        component: () => import("@/views/login/login"),
      },
      {
        path: "/startPracticing",
        name: "startPracticing",
        component: () => import("@/views/question-bank/start-practicing"),
      },
      {
        path: "/analysis",
        name: "analysis",
        component: () => import("@/views/question-bank/analysis"),
      },
      {
        path: "/answer",
        name: "answer",
        component: () => import("@/views/answer/answer"),
      },
      {
        path: "/myCourse",
        name: "myCourse",
        component: () => import("@/views/my-course/index"),
      },
      {
        path: "/reply",
        name: "reply",
        component: () => import("@/views/answer/reply"),
      },
      {
        path: "/classList",
        name: "classList",
        component: () => import("@/views/my-course/classList"),
      },
      {
        path: "/allVideo",
        name: "allVideo",
        component: () => import("@/views/home/allVideo"),
      },
      {
        path: "/personalCenter",
        name: "personalCenter",
        component: () => import("@/views/personalCenter/personalCenter"),
      },
    ],
  },
  {
    path: "/courseContent",
    name: "courseContent",
    component: () => import("@/views/course/courseContent"),
  },
  {
    path: "/liveBroadcastingRoom",
    name: "liveBroadcastingRoom",
    component: () => import("@/views/live-broadcast/live-broadcasting-room"),
  },
  {
    path: "/viewVideo",
    name: "viewVideo",
    component: () => import("@/views/home/viewVideo"),
  },
  {
    path: "/viewLive",
    name: "viewLive",
    component: () => import("@/views/home/viewLive"),
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

export default router;
